<template>
    <div class="contact_box">
        <div class="contact_way">
            <p class="contact_way_box" v-for="(item, index) in data">
                <el-select
                    v-model="item.contactWay"
                    size="mini"
                    style="width: 65px"
                    placeholder="方式"
                >
                    <el-option label="电话" :value="1"></el-option>
                    <el-option label="微信" :value="2"></el-option>
                    <el-option label="邮箱" :value="3"></el-option>
                    <el-option label="QQ" :value="4"></el-option>
                    <el-option label="短信" :value="5"></el-option>
                    <el-option label="拜访" :value="6"></el-option>
                    <el-option label="其他" :value="7"></el-option>
                </el-select>
                <el-input
                    :style="{
                        width: 'calc(100% - 20px - 105px)',

                        marginLeft: '8px',
                    }"
                    v-model="item.contactContent"
                    placeholder="请输入联系内容"
                    size="mini"
                ></el-input>
                <el-button
                    v-if="index == 0"
                    @click="addContact(index)"
                    type="primary"
                    class="el-icon-plus"
                    circle
                    size="mini"
                ></el-button>
                <el-button
                    v-if="index > 0"
                    @click.prevent="removeContact(index)"
                    class="el-icon-minus"
                    type="primary"
                    circle
                    size="mini"
                ></el-button>
            </p>
        </div>
        <div class="but">
            <el-button
                round
                type="primary"
                size="mini"
                style="
                    background: none;
                    border-color: #d5d6df;
                    color: #666666;
                    margin-left: auto;
                "
                @click="onClose"
                >取消</el-button
            >
            <el-button
                round
                type="primary"
                style="
                    background: #2370eb;
                    border-color: #2370eb;
                    color: #fff;
                    margin-right: 30px;
                "
                size="mini"
                @click="onClose"
                >确定</el-button
            >
        </div>
    </div>
</template>

<script>
export default {
    name: '',
    props: {},
    data() {
        return {
            data: [],
            value: '',
        };
    },
    components: {},
    created() {},
    methods: {
        getData(data) {
            this.data = data;
        },
        addContact() {
            this.data.push({});
        },
        removeContact(index) {
            this.data.splice(index, 1);
            console.log(this.data);
            console.log();
            this.data = JSON.parse(JSON.stringify(this.data));
        },
        onClose() {
            this.$emit('close');
        },
    },
};
</script>

<style lang="scss" scoped>
.contact_box {
    .contact_way {
        height: 290px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        padding: 20px 30px 50px;
    }
    p {
        padding-left: 20px;
        margin-top: 10px;
        //    font-weight: 600;
        line-height: 28px;
        //    /deep/ .el-input__inner {
        //        font-weight: 600 !important;
        //        font-size: 14px !important;
        //        color: #333 !important;
        //    }
    }
    .contact_way_box {
        padding-left: 0;
        width: 50%;
        min-width: 250px;
        position: relative;
        /deep/ .el-input--suffix .el-input__inner {
            padding: 0 10px;
        }
    }
    .el-button--mini.is-circle {
        padding: 2px;
        background: #fff;
        color: #2370eb;
        font-weight: 600;
        width: 20px;
        height: 20px;
        border: 2px solid #2370eb;
        margin-left: 5px;
    }
    .but {
        width: 100%;
        height: 48px;
        border-top: 1px solid #e5e5e5;
        display: flex;
        align-items: center;
        justify-content: space-between;
        button {
            width: 72px;
        }
    }
}
</style>
