import http from '@/utils/http.js'

// 通讯录列表
export function addressList(data) {
    return http({
        url: "/jasoboss/address/book/list",
        method: "post",
        data
    })
}
// 通讯录新增
export function addressAdd(data) {
    return http({
        url: "/jasoboss/address/book/add",
        method: "post",
        data
    })
}
// 修改
export function addressEdit(data) {
    return http({
        url: "/jasoboss/address/book/update",
        method: "post",
        data
    })
}
// 单个
export function addressGet(data) {
    return http({
        url: "/jasoboss/address/book/get",
        method: "post",
        data
    })
}
// 删除
export function addressDel(data) {
    return http({
        url: "/jasoboss/address/book/way/delete",
        method: "post",
        data
    })
}
// 历史
export function addressHistory(data) {
    return http({
        url: "/jasoboss/address/book/history/list",
        method: "post",
        data
    })
}
