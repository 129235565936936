<template>
    <div class="bank_box">
        <div class="main">
            <div>
                <span>支付宝</span>
                <el-input
                    style="margin-right: 10px; width: 170px"
                    v-model="form.alipayNo"
                    placeholder="支付宝账号"
                    size="mini"
                ></el-input>
                <el-input
                    size="mini"
                    style="width: 90px"
                    v-model="form.alipayName"
                    placeholder="姓名"
                >
                </el-input>
            </div>
            <div class="info">
                <span>银行信息</span>
                <div>
                    <div>
                        <el-input
                            size="mini"
                            style="width: 270px; margin-bottom: 6px"
                            v-model="form.bankNo"
                            placeholder="银行卡号"
                        >
                        </el-input>
                    </div>
                    <div>
                        <el-input
                            size="mini"
                            style="width: 270px; margin-bottom: 6px"
                            v-model="form.bankDeposit"
                            placeholder="开户行"
                        >
                        </el-input>
                    </div>
                    <div>
                        <el-input
                            size="mini"
                            style="width: 270px"
                            v-model="form.bankName"
                            placeholder="姓名"
                        >
                        </el-input>
                    </div>
                </div>
            </div>
        </div>
        <div class="but">
            <el-button
                round
                type="primary"
                size="mini"
                style="
                    background: none;
                    border-color: #d5d6df;
                    color: #666666;
                    margin-left: auto;
                "
                @click="onClose"
                >取消</el-button
            >
            <el-button
                round
                type="primary"
                style="
                    background: #2370eb;
                    border-color: #2370eb;
                    color: #fff;
                    margin-right: 30px;
                "
                size="mini"
                @click="onSubmit"
                >确定</el-button
            >
        </div>
    </div>
</template>

<script>
import { addressEdit } from '@/api/user/mail.js';
export default {
    name: '',
    props: {},
    data() {
        return {
            form: {},
        };
    },
    components: {},
    created() {},
    methods: {
        getData(data) {
            this.form = data;
        },
        onSubmit() {
            let data = {
                param: {
                    alipayNo: this.form.alipayNo,
                    bankNo: this.form.bankNo,
                    alipayName: this.form.alipayName,
                    bankDeposit: this.form.bankDeposit,
                    bankName: this.form.bankName,
                    id: this.form.id,
                },
            };
            addressEdit(data).then((res) => {
                if (res.code == 200) {
                    this.onClose();
                }
            });
        },
        onClose() {
            this.$emit('close');
        },
    },
};
</script>

<style lang="scss" scoped>
.bank_box {
    .main {
        width: 350px;
        margin: 0 auto;
        height: 290px;
        padding-top: 20px;
        > div {
            > span {
                display: inline-block;
                width: 65px;
                margin-right: 12px;
            }
        }
    }
    .but {
        width: 100%;
        height: 48px;
        border-top: 1px solid #e5e5e5;
        display: flex;
        align-items: center;
        justify-content: space-between;
        button {
            width: 72px;
        }
    }
    .info {
        display: flex;
        margin-top: 16px;
    }
    /deep/ .el-input__inner {
        font-weight: 600 !important;
        font-size: 14px !important;
        color: #333 !important;
    }
}
</style>
