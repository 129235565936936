<template>
    <div class="table-box">
        <el-table
            ref="multipleTable"
            :data="tableData"
            height="100%"
            :header-cell-style="{
                background: '#FAFAFA',
                color: '#333333',
                'font-size': '14px',
                'font-weight': '500',
            }"
        >
            <el-table-column width="10" show-overflow-tooltip>
            </el-table-column>
            <el-table-column width="150" label="操作时间" show-overflow-tooltip>
                <template slot-scope="scope">
                    {{
                        scope.row.createTime
                            ? scope.row.createTime.slice(0, 16)
                            : '- -' || '- -'
                    }}
                </template>
            </el-table-column>

            <el-table-column width="90" label="操作人员" show-overflow-tooltip>
                <template slot-scope="scope">
                    {{ scope.row.adminName || '- -' }}
                </template>
            </el-table-column>

            <el-table-column label="操作内容" show-overflow-tooltip>
                <template slot-scope="scope">
                    {{ scope.row.remark || '- -' }}
                </template>
            </el-table-column>
            <div slot="empty" class="empty">
                <img src="@/assets/zanwushuju.png" />
                <span style="display: block">暂无数据～</span>
            </div>
        </el-table>
    </div>
</template>

<script>
import { addressHistory } from '@/api/user/mail.js';
export default {
    name: '',
    props: {},
    data() {
        return {
            tableData: [],
        };
    },
    components: {},
    created() {},
    methods: {
        getData(bookId, pageNum, pageSize) {
            let data = {
                param: {
                    bookId: bookId,
                },
                pageNum: pageNum,
                pageSize: pageSize,
            };
            addressHistory(data).then((res) => {
                if (res.code == 200) {
                    this.tableData = res.data.list;
                    this.$emit('totalNum', res.data.total);
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.table-box {
    width: 100%;
    height: calc(100% - 102px);
    .icon-ziyuanxhdpi {
        color: #2370eb;
        cursor: pointer;
    }
}
</style>
