<template>
    <div class="details_box">
        <div
            class="header_box"
            :style="{
                background:
                    'url(' + require('@/assets/bg2.png') + ')  no-repeat',
                backgroundSize: 'cover',
            }"
        >
            <img src="@/img/wodekehu.png" alt="" />
            <span>{{ oneContact.adminName || '- -' }}</span>
            <p>{{ oneContact.departmentName || '- -' }}</p>
            <p>创建时间：{{ oneContact.createTime || '- -' }}</p>
            <p style="flex: 1">
                更新时间：{{ oneContact.updateTime || '- -' }}
            </p>
            <el-button
                v-show="!isEdit"
                type="primary"
                style="
                    background: #fff;
                    border-color: #2370eb;
                    color: #2370eb;
                    padding: 6px 12px;
                    float: right;
                "
                size="mini"
                icon="el-icon-arrow-left
"
                @click="
                    () => {
                        $router.go(-1);
                    }
                "
            >
                返回列表</el-button
            >
        </div>
        <div class="basic_info">
            <div class="company_info">
                <h2>
                    <i class="iconfont icon-gongsi"></i>
                    公司信息
                    <el-button
                        v-show="!isEdit && btnP.mailUpdata"
                        type="primary"
                        style="
                            background: #fff;
                            border-color: #2370eb;
                            color: #2370eb;
                            padding: 4px 10px;
                            float: right;
                        "
                        size="mini"
                        @click="
                            () => {
                                isEdit = true;
                                oneContact.sourceId == 0
                                    ? (oneContact.sourceId = '')
                                    : oneContact.sourceId;
                            }
                        "
                        >编辑</el-button
                    >
                    <el-button
                        v-show="isEdit"
                        type="primary"
                        style="
                            background: #fff;
                            border-color: #e5e5e5;
                            color: #333;
                            padding: 4px 10px;
                            float: right;
                            margin-left: 0;
                        "
                        size="mini"
                        @click="
                            () => {
                                isEdit = false;
                                oneContact.sourceId == 0
                                    ? (oneContact.sourceId = '')
                                    : oneContact.sourceId;
                            }
                        "
                        >取消</el-button
                    >
                    <el-button
                        v-show="isEdit"
                        type="primary"
                        style="
                            background: #fff;
                            border-color: #2370eb;
                            color: #2370eb;
                            padding: 4px 10px;
                            float: right;
                        "
                        size="mini"
                        @click="onSubmit"
                        >完成</el-button
                    >
                </h2>
                <div class="company_main" v-show="isEdit">
                    <p>
                        <span>姓名</span>
                        <el-input
                            size="mini"
                            style="width: 60%"
                            v-model="oneContact.contactName"
                        ></el-input>
                    </p>
                    <p>
                        <span>客户</span>
                        <el-select
                            remote
                            :remote-method="remoteMethod"
                            v-model="oneContact.sourceId"
                            filterable
                            placeholder="请选择或输入关键字搜索"
                            size="mini"
                            style="width: 60%"
                            clearable
                            @change="changeSource"
                        >
                            <el-option
                                v-for="item in Relation"
                                :key="item.id"
                                :label="item.companyName"
                                :value="item.id"
                            >
                            </el-option>
                        </el-select>
                    </p>
                    <p>
                        <span>公司</span>
                        <el-input
                            size="mini"
                            style="width: 60%"
                            v-model="oneContact.companyName"
                        ></el-input>
                    </p>
                    <p>
                        <span>部门</span>
                        <el-input
                            size="mini"
                            style="width: 60%"
                            v-model="oneContact.department"
                        ></el-input>
                    </p>
                    <p>
                        <span>职务</span>
                        <el-input
                            size="mini"
                            style="width: 60%"
                            v-model="oneContact.duty"
                        ></el-input>
                    </p>
                </div>
                <div class="company_main" v-show="!isEdit">
                    <p>
                        <span>姓名</span>
                        <em>{{ oneContact.contactName || '- -' }}</em>
                    </p>
                    <p>
                        <span>客户</span>
                        <el-select
                            remote
                            :disabled="!isEdit"
                            :remote-method="remoteMethod"
                            v-model="oneContact.sourceId"
                            filterable
                            size="mini"
                            style="width: 60%"
                            clearable
                            @change="changeSource"
                            placeholder="- -"
                        >
                            <el-option
                                v-for="item in Relation"
                                :key="item.id"
                                :label="item.companyName"
                                :value="item.id"
                            >
                            </el-option>
                        </el-select>
                    </p>
                    <p>
                        <span>公司</span>
                        <em>{{ oneContact.companyName || '- -' }}</em>
                    </p>
                    <p>
                        <span>部门</span>
                        <em>{{ oneContact.department || '- -' }}</em>
                    </p>
                    <p>
                        <span>职务</span>
                        <em>{{ oneContact.duty || '- -' }}</em>
                    </p>
                </div>
            </div>
            <div
                class="person_info"
                :style="{
                    background:
                        'url(' +
                        require('@/img/tyx.png') +
                        ') 50% 50% no-repeat',
                    backgroundSize: 'cover',
                }"
            >
                <h2>
                    <img
                        src="@/img/yonghuziliaogerenxinxigerenziliao.png"
                        alt=""
                    />
                    个人信息
                    <el-button
                        v-show="!isEditPerson && btnP.mailUpdata"
                        type="primary"
                        style="
                            background: #fff;
                            border-color: #2370eb;
                            color: #2370eb;
                            padding: 4px 10px;
                            float: right;
                        "
                        size="mini"
                        @click="
                            () => {
                                isEditPerson = true;
                                oneContact.sex == 0
                                    ? (oneContact.sex = '')
                                    : oneContact.sex;
                            }
                        "
                        >编辑</el-button
                    >

                    <el-button
                        v-show="isEditPerson"
                        type="primary"
                        style="
                            background: #fff;
                            border-color: #e5e5e5;
                            color: #333;
                            padding: 4px 10px;
                            float: right;
                            margin-left: 0;
                        "
                        size="mini"
                        @click="
                            () => {
                                isEditPerson = false;
                            }
                        "
                        >取消</el-button
                    >
                    <el-button
                        v-show="isEditPerson"
                        type="primary"
                        style="
                            background: #fff;
                            border-color: #2370eb;
                            color: #2370eb;
                            padding: 4px 10px;
                            float: right;
                        "
                        size="mini"
                        @click="onSubmitPerson"
                        >完成</el-button
                    >
                    <el-button
                        type="primary"
                        style="
                            background: #fff;
                            border-color: #2370eb;
                            color: #2370eb;
                            padding: 4px 10px;
                            float: right;
                            margin-right: 10px;
                        "
                        size="mini"
                        @click="upDateBank"
                        >个人银行信息</el-button
                    >
                </h2>
                <div class="person_main">
                    <div v-show="isEditPerson">
                        <div style="min-width: 285px; width: 30%">
                            <p>
                                <span>联系ID</span>
                                <em style="width: calc(100% - 70px)">
                                    {{ oneContact.bookNo || '- -' }}
                                </em>
                            </p>
                            <p>
                                <span>昵称</span>
                                <el-input
                                    style="width: calc(100% - 70px)"
                                    size="mini"
                                    v-model="oneContact.nickName"
                                ></el-input>
                            </p>
                            <p>
                                <span>性别</span>
                                <el-select
                                    v-model="oneContact.sex"
                                    style="width: calc(100% - 70px)"
                                    size="mini"
                                >
                                    <el-option label="男" :value="1">
                                    </el-option>
                                    <el-option label="女" :value="2">
                                    </el-option>
                                </el-select>
                            </p>
                            <p>
                                <span>生日</span>
                                <el-date-picker
                                    v-model="oneContact.birthday"
                                    type="date"
                                    placeholder="选择日期"
                                    size="mini"
                                    value-format="yyyy-MM-dd"
                                    style="width: calc(100% - 70px)"
                                >
                                </el-date-picker>
                            </p>
                        </div>
                        <div class="contact_way">
                            <p
                                class="contact_way_box"
                                v-for="(
                                    item, index
                                ) in oneContact.bookWayDOList"
                            >
                                <el-select
                                    v-if="index < 7"
                                    v-model="item.contactWay"
                                    size="mini"
                                    style="width: 65px"
                                    placeholder="方式"
                                >
                                    <el-option
                                        label="电话"
                                        :value="1"
                                    ></el-option>
                                    <el-option
                                        label="微信"
                                        :value="2"
                                    ></el-option>
                                    <el-option
                                        label="邮箱"
                                        :value="3"
                                    ></el-option>
                                    <el-option
                                        label="QQ"
                                        :value="4"
                                    ></el-option>
                                    <el-option
                                        label="短信"
                                        :value="5"
                                    ></el-option>
                                    <el-option
                                        label="拜访"
                                        :value="6"
                                    ></el-option>
                                    <el-option
                                        label="其他"
                                        :value="7"
                                    ></el-option>
                                </el-select>
                                <el-input
                                    v-if="index < 7"
                                    :style="{
                                        width:
                                            index > 3
                                                ? 'calc(100% - 20px - 105px)'
                                                : 'calc(100% - 20px - 80px)',
                                        marginLeft: '8px',
                                    }"
                                    v-model="item.contactContent"
                                    placeholder="请输入联系内容"
                                    size="mini"
                                ></el-input>
                                <el-button
                                    v-if="index == 0"
                                    :disabled="
                                        oneContact.bookWayDOList.length > 7
                                    "
                                    @click="addContact(index)"
                                    type="primary"
                                    class="el-icon-plus"
                                    circle
                                    size="mini"
                                ></el-button>
                                <el-button
                                    v-if="index > 0 && index < 7"
                                    @click.prevent="removeContact(index)"
                                    class="el-icon-minus"
                                    type="primary"
                                    circle
                                    size="mini"
                                ></el-button>

                                <el-button
                                    v-if="index == 7"
                                    type="primary"
                                    style="
                                        background: #fff;
                                        border-color: #2370eb;
                                        color: #2370eb;
                                        padding: 4px 20px;
                                    "
                                    size="mini"
                                    @click="addMoreContact"
                                    >添加更多联系方式</el-button
                                >
                            </p>
                        </div>
                    </div>
                    <div v-show="!isEditPerson">
                        <div style="min-width: 285px; width: 30%">
                            <p>
                                <span>联系ID</span>
                                <em style="width: calc(100% - 70px)">
                                    {{ oneContact.bookNo || '- -' }}
                                </em>
                            </p>
                            <p>
                                <span>昵称</span>
                                <em style="width: calc(100% - 70px)">
                                    {{ oneContact.nickName || '- -' }}
                                </em>
                            </p>
                            <p>
                                <span>性别</span>
                                <em style="width: calc(100% - 70px)">
                                    {{
                                        oneContact.sex == 1
                                            ? '男'
                                            : oneContact.sex == 2
                                            ? '女'
                                            : '其他'
                                    }}
                                </em>
                            </p>
                            <p>
                                <span>生日</span>
                                <em style="width: calc(100% - 70px)">
                                    {{
                                        oneContact.birthday
                                            ? oneContact.birthday.slice(0, 10)
                                            : '- -' || '- -'
                                    }}
                                </em>
                            </p>
                        </div>
                        <div class="contact_way">
                            <p
                                class="contact_way_box"
                                v-for="(
                                    item, index
                                ) in oneContact.bookWayDOList"
                                :key="item.id"
                            >
                                <span style="width: 65px">{{
                                    $tableDataHandle.contactWay(item)
                                }}</span>
                                <em
                                    :style="{
                                        width:
                                            index > 3
                                                ? 'calc(100% - 20px - 105px)'
                                                : 'calc(100% - 20px - 80px)',
                                        marginLeft: '8px',
                                    }"
                                >
                                    {{ item.contactContent || '- -' }}
                                </em>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="tag_box">
                    <p>
                        <span>联系人标签</span>
                        <em
                            :key="index"
                            :class="item.category == 0 ? 'blue' : 'yellow'"
                            class="labelList"
                            v-for="(item, index) in $choiceLabel.state
                                .labelList"
                            >{{ item.tagName }}
                            <em
                                class="cancel"
                                v-show="isEditPerson"
                                @click="onDeleteLabel(item)"
                            >
                                <em class="el-icon-close"></em>
                            </em>
                        </em>
                        <el-button
                            type="primary"
                            icon="el-icon-plus"
                            size="small"
                            v-show="isEditPerson"
                            @click="onChoiceLabel()"
                            style="
                                background: #2370eb;
                                border-color: #2370eb;
                                color: #fff;
                                padding: 5px;
                            "
                        ></el-button>
                    </p>
                </div>
            </div>
        </div>
        <div class="opt_box">
            <h2>
                <i class="iconfont icon-dingdan1"></i>
                操作日志
            </h2>
            <Table ref="table" @totalNum="totalNum"></Table>
            <div class="page-box">
                <el-pagination
                    @current-change="handleCurrentChange"
                    @size-change="handleSizeChange"
                    :current-page.sync="currentPage"
                    :page-size="pagesize"
                    :page-sizes="[10, 20, 50]"
                    layout="total,sizes,prev, pager, next, jumper"
                    :total="total"
                ></el-pagination>
            </div>
        </div>
        <el-dialog
            :visible.sync="dialogContact"
            width="560px"
            :before-close="handleClose"
            append-to-body
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    添加联系方式
                </div>
            </template>
            <Contact
                ref="contact"
                @close="
                    () => {
                        dialogContact = false;
                    }
                "
            ></Contact>
        </el-dialog>
        <el-dialog
            :visible.sync="dialogBank"
            width="560px"
            :before-close="handleClose"
            append-to-body
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    个人银行信息
                </div>
            </template>
            <Bank ref="bank" @close="handleClose"></Bank>
        </el-dialog>

        <el-dialog
            :visible.sync="dialogChoice"
            width="560px"
            :before-close="handleClose"
            append-to-body
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    选择标签
                </div>
            </template>
            <ChoiceLabel
                @close="handleClose"
                @addLabel="addLabel"
                @addLabel2="addLabel2"
                :isGet="isGet"
            />
        </el-dialog>
        <el-dialog
            :visible.sync="dialogAddlabel"
            width="560px"
            :before-close="handleAddlabel"
            append-to-body
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    新增个人标签
                </div>
            </template>
            <AddLable
                @close="handleAddlabel"
                :category="1"
                @addLabel="addLabel"
            />
        </el-dialog>
        <el-dialog
            :visible.sync="dialogAddlabel2"
            width="560px"
            :before-close="handleAddlabel"
            append-to-body
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    新增部门标签
                </div>
            </template>
            <AddLable
                @close="handleAddlabel"
                :category="2"
                @addLabel="addLabel"
            />
        </el-dialog>
    </div>
</template>

<script>
import { addressGet, addressEdit, addressDel } from '@/api/user/mail.js';
import Table from './table.vue';
import Contact from './contact.vue';
import Bank from './bank.vue';

import ChoiceLabel from '../choice-label/choiceLabel.vue';
import AddLable from '../choice-label/addLabel.vue';
import { relationList, relationDelete } from '@/api/user/label.js';
import { customerList } from '@/api/report/report.js';
export default {
    name: '',
    props: {},
    data() {
        return {
            value: '',
            isEdit: false,
            isEditPerson: false,
            dialogContact: false,
            dialogBank: false,
            dialogChoice: false,
            dialogAddlabel: false,
            dialogAddlabel2: false,
            value2: [{}],
            currentPage: 1,
            pagesize: 20,
            total: 0,
            isGet: false,
            oneContact: {},
            Relation: [],
            btnP: {},
        };
    },
    components: {
        Table,
        Contact,
        Bank,
        ChoiceLabel,
        AddLable,
    },
    created() {},
    mounted() {
        this.addressGet();
    },
    methods: {
        addressGet() {
            var btnArr = JSON.parse(sessionStorage.getItem('isButArr'));
            let btn = {};
            for (let i in btnArr) {
                btn['' + btnArr[i].route] = true;
            }
            this.btnP = btn;
            sessionStorage.setItem('customerlist', 1);
            let data = {
                param: {},
            };
            if (this.$route.query.contactId) {
                data.param.contactId = this.$route.query.contactId;
            }
            if (this.$route.query.id) {
                data.param.id = this.$route.query.id;
            }
            addressGet(data).then((res) => {
                if (res.code == 200) {
                    this.oneContact = res.data || {};
                    this.oneContact.sourceId == 0
                        ? (this.oneContact.sourceId = '')
                        : this.oneContact.sourceId;
                    this.getLabelData();
                    this.getRelation(res.data, '', res.data.sourceId);
                    this.getTableData();
                }
            });
        },
        getTableData() {
            setTimeout(() => {
                this.$refs.table.getData(
                    this.oneContact.id,
                    this.currentPage,
                    this.pagesize
                );
            }, 0);
        },
        totalNum(data) {
            this.total = data;
        },
        onSubmit() {
            let data = {
                param: {
                    contactName: this.oneContact.contactName,
                    sourceId: this.oneContact.sourceId,
                    companyName: this.oneContact.companyName,
                    department: this.oneContact.department,
                    duty: this.oneContact.duty,
                    source: this.oneContact.source,
                    id: this.oneContact.id,
                },
            };
            if (!this.oneContact.contactName) {
                return this.$message.error('请输入姓名');
            }
            addressEdit(data).then((res) => {
                if (res.code == 200) {
                    this.isEdit = false;
                    this.$message.success('操作成功');
                    this.addressGet();
                }
            });
        },
        onSubmitPerson() {
            let data = {
                param: {
                    nickName: this.oneContact.nickName,
                    sex: this.oneContact.sex,
                    birthday: this.oneContact.birthday,
                    bookWayDOList: this.oneContact.bookWayDOList,
                    id: this.oneContact.id,
                },
            };
            for (let i in this.oneContact.bookWayDOList) {
                if (
                    !this.oneContact.bookWayDOList[i].contactWay ||
                    !this.oneContact.bookWayDOList[i].contactContent
                ) {
                    return this.$message.error('请输入完整联系方式');
                }
            }
            addressEdit(data).then((res) => {
                if (res.code == 200) {
                    this.isEditPerson = false;
                    this.$message.success('操作成功');
                    this.addressGet();
                }
            });
        },
        changeSource(val) {
            console.log(123);
            if (val) {
                this.oneContact.source = 2;
                let data = {
                    param: {
                        sourceId: val,
                        source: this.oneContact.source,
                        id: this.oneContact.id,
                    },
                };
                addressEdit(data).then((res) => {
                    if (res.code == 200) {
                        this.$message.success('操作成功');
                        this.addressGet();
                    }
                });
            } else {
                let data = {
                    param: {
                        sourceId: 0,
                        id: this.oneContact.id,
                    },
                };
                addressEdit(data).then((res) => {
                    if (res.code == 200) {
                        this.$message.success('操作成功');
                        this.addressGet();
                    }
                });
            }
        },
        remoteMethod(query) {
            this.getRelation(this.oneContact, query);
        },
        getRelation(row, name, sourceId = '') {
            if (row.customerId == 0) {
                row.customerId = '';
            }
            this.form = row;
            let data = {
                param: {
                    customerStatusList: [1, 2, 3],
                },
                pageNum: 1,
                pageSize: 20,
            };
            if (this.btnP.searchUser && !this.btnP.depSearch) {
                data.param.departmentId =
                    sessionStorage.getItem('departmentId');
            } else if (!this.btnP.searchUser && !this.btnP.depSearch) {
                data.param.adminId = sessionStorage.getItem('adminId');
            }
            if (name) {
                data.param.name = name;
            }
            if (sourceId) {
                data.param.id = sourceId;
            }
            customerList(data).then((res) => {
                if (row.customerId) {
                    res.data.list.push({
                        id: row.customerId,
                        companyName: row.customerName,
                    });
                    var obj = {};
                    this.Relation = res.data.list.reduce((prev, cur) => {
                        obj[cur.id]
                            ? ''
                            : (obj[cur.id] = true && prev.push(cur));
                        return prev;
                    }, []);
                } else {
                    this.Relation = res.data.list;
                }
            });
        },
        addContact() {
            this.oneContact.bookWayDOList.push({});
        },
        removeContact(index) {
            if (this.oneContact.bookWayDOList[index].id) {
                let data = {
                    param: {
                        id: this.oneContact.bookWayDOList[index].id,
                    },
                };
                addressDel(data).then((res) => {
                    if (res.code == 200) {
                        this.oneContact.bookWayDOList.splice(index, 1);
                    }
                });
            } else {
                this.oneContact.bookWayDOList.splice(index, 1);
            }
        },
        // 获取绑定标签
        getLabelData() {
            let data = {
                param: {
                    bandingTableType: 5,
                },
            };
            if (this.$route.query.id) {
                data.param.bandingId = this.$route.query.id;
            }
            if (this.$route.query.contactId) {
                data.param.bandingId = this.oneContact.id;
            }
            relationList(data).then((res) => {
                this.$choiceLabel.commit(
                    'getLabelList',
                    JSON.stringify(res.data.list)
                );
            });
        },
        // 新增标签
        addLabel() {
            this.dialogChoice = false;
            this.dialogAddlabel = true;
        },
        // 新增标签
        addLabel2() {
            this.dialogChoice = false;
            this.dialogAddlabel2 = true;
        },
        // 选择标签
        onChoiceLabel() {
            this.dialogChoice = true;
            let that = this;
            sessionStorage.setItem('cusAdminId', this.oneContact.adminId);
            setTimeout(() => {
                that.isGet = !that.isGet;
                this.$choiceLabel.commit('isMultiple', false);
                this.$choiceLabel.commit('getType', '3');
                this.$choiceLabel.commit('getBindingType', '5');
                this.$choiceLabel.commit('getIsDetails', true);
            }, 200);
        },
        // 移除标签
        onDeleteLabel(item) {
            this.$choiceLabel.commit('removeLabel', JSON.stringify(item));
            let data = {
                param: {
                    id: item.id,
                },
            };
            relationDelete(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('操作成功');
                }
            });
        },
        // 分页
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getTableData();
        },
        handleSizeChange(val) {
            this.pagesize = val;
            this.getTableData();
        },
        addMoreContact() {
            this.dialogContact = true;
            setInterval(() => {
                this.$refs.contact.getData(this.oneContact.bookWayDOList);
            }, 0);
        },
        upDateBank() {
            this.dialogBank = true;
            setInterval(() => {
                this.$refs.bank.getData(this.oneContact);
            }, 0);
        },
        handleAddlabel() {
            this.dialogAddlabel = false;
            this.dialogAddlabel2 = false;
            this.dialogChoice = true;
            this.isGet = !this.isGet;
        },
        handleClose() {
            this.dialogContact = false;
            this.dialogBank = false;
            this.dialogAddlabel2 = false;
            this.dialogAddlabel = false;
            this.dialogChoice = false;
            this.addressGet();
        },
    },
    destroyed() {
        this.$choiceLabel.commit('emptyData');
        sessionStorage.removeItem('customerlist');
        sessionStorage.removeItem('cusAdminId');
    },
};
</script>

<style lang="scss" scoped>
.details_box {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    .header_box {
        height: 52px;
        display: flex;
        align-items: center;
        padding: 0 16px;
        font-size: 14px;
        margin-bottom: 12px;
        img {
            margin-right: 12px;
        }
        p {
            margin-left: 40px;
        }
    }
    .basic_info {
        display: flex;
        height: 240px;
        margin-bottom: 12px;
        .company_info {
            margin-right: 12px;
            flex: 0.5;
            box-sizing: border-box;
            padding: 12px 16px;
            background-color: #fff;
            h2 {
                font-size: 14px;
                font-weight: 600;
                i {
                    font-weight: normal;
                    color: #2370eb;
                }
            }
            .company_main {
                font-size: 14px;
                p {
                    padding-left: 20px;
                    margin-top: 10px;
                    font-weight: 600;
                    line-height: 28px;
                    span {
                        margin-right: 20px;
                        color: #666;
                        font-weight: normal;
                    }
                    /deep/ .el-input__inner {
                        font-weight: 600 !important;
                        font-size: 14px !important;
                        color: #333 !important;
                    }
                    em {
                        display: inline-block;
                        font-style: normal;
                        width: 60%;
                        color: #333;
                    }
                }
            }
        }
        .person_info {
            flex: 1;
            box-sizing: border-box;
            padding: 12px 16px;
            background-color: #fff;
            h2 {
                font-size: 14px;
                font-weight: 600;
                i {
                    font-weight: normal;
                    color: #2370eb;
                }
            }
            .person_main {
                > div {
                    display: flex;
                    font-size: 14px;
                    height: 153px;
                    width: 100%;
                    .contact_way {
                        height: 100%;
                        float: right;
                        width: calc(100% - 30%);
                        overflow: hidden;
                        display: flex;
                        flex-direction: column;
                        flex-wrap: wrap;
                        p {
                            padding-left: 0;
                        }
                    }
                    p {
                        padding-left: 20px;
                        margin-top: 10px;
                        font-weight: 600;
                        line-height: 28px;
                        span {
                            margin-right: 20px;
                            color: #666;
                            display: inline-block;
                            width: 45px;
                            font-weight: normal;
                        }
                        /deep/ .el-input__inner {
                            font-weight: 600 !important;
                            font-size: 14px !important;
                            color: #333 !important;
                        }
                        em {
                            display: inline-block;
                            font-style: normal;
                            width: 200px;
                            color: #333;
                        }
                    }
                }
            }
            .tag_box {
                p {
                    padding-left: 20px;
                    margin-top: 10px;
                    font-weight: 600;
                    line-height: 28px;
                    span {
                        margin-right: 20px;
                        color: #666;
                        display: inline-block;
                        font-size: 14px;
                        font-weight: normal;
                    }
                }
            }
        }
    }
    .opt_box {
        flex: 1;
        h2 {
            height: 54px;
            line-height: 54px;
            padding-left: 16px;
            font-size: 14px;
            background-color: #fff;
            box-sizing: border-box;
            border-bottom: 1px solid #e5e5e5;
            i {
                color: #f06f1e;
                font-weight: normal;
            }
        }
    }
    .contact_way_box {
        padding-left: 0;
        width: 50%;
        min-width: 250px;
        position: relative;
        margin-top: 9px;
        /deep/ .el-input--suffix .el-input__inner {
            padding: 0 10px;
        }
    }
    .contact_way_box:nth-child(n) {
        margin-left: 10px;
    }
    .el-button--mini.is-circle {
        padding: 2px;
        background: #fff;
        color: #2370eb;
        font-weight: 600;
        width: 20px;
        height: 20px;
        border: 2px solid #2370eb;
        margin-left: 5px;
    }
    .page-box {
        background: #fff;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .labelList {
        display: inline-block;
        padding: 0 8px;
        height: 24px;
        border: 1px solid #e5e5e5;
        border-radius: 2px;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        text-align: center;
        line-height: 24px;
        margin-right: 18px;
        cursor: pointer;
        position: relative;
        .cancel {
            position: absolute;
            display: block;
            width: 0;
            height: 0;
            border-top: 10px solid rgba(255, 255, 255, 0);
            border-right: 10px solid rgba(255, 255, 255, 0);
            border-bottom: 10px solid rgba(255, 255, 255, 0);
            border-left: 10px solid yellow;
            -webkit-transform: rotate(45deg); /* for Chrome || Safari */
            -moz-transform: rotate(45deg); /* for Firefox */
            -ms-transform: rotate(45deg); /* for IE */
            -o-transform: rotate(45deg);
            right: -10px;
            bottom: -10px;
            .el-icon-close {
                color: #fff;
                position: absolute;
                font-size: 8px;
                left: -12px;
                top: -6px;
                -webkit-transform: rotate(45deg); /* for Chrome || Safari */
                -moz-transform: rotate(45deg); /* for Firefox */
                -ms-transform: rotate(45deg); /* for IE */
                -o-transform: rotate(45deg);
            }
        }
    }
    .blue {
        color: #2370eb;
        border-color: #2370eb;
        .cancel {
            border-left: 10px solid #2370eb;
        }
    }
    .yellow {
        color: #fa8c15;
        border-color: #fa8c15;
        .cancel {
            border-left: 10px solid #fa8c15;
        }
    }
    /deep/ .el-input.is-disabled .el-input__inner {
        background-color: transparent !important;
        padding: 0 !important;
    }
}
</style>
